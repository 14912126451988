<template>
  <div class="order-descriptions" v-if="show">
    <h3>订单信息</h3>
    <el-descriptions :column="2" border>
      <el-descriptions-item label="订单类型">{{
        orderData.commodity
      }}</el-descriptions-item>
      <el-descriptions-item label="订单金额">{{
        orderData.price
      }}</el-descriptions-item>
      <el-descriptions-item label="订单ID" :span="2">{{
        orderData.orderId
      }}</el-descriptions-item>

      <el-descriptions-item label="下单时间" :span="2">{{
        $filters.dateFilter(orderData.createTime, 'YYYY-MM-DD HH:mm:ss')
      }}</el-descriptions-item>

      <el-descriptions-item label="购买时间" :span="2">{{
        $filters.dateFilter(orderData.payTime, 'YYYY-MM-DD HH:mm:ss')
      }}</el-descriptions-item>

      <el-descriptions-item label="订单来源">
        {{ orderData.channel }}
      </el-descriptions-item>
      <el-descriptions-item label="手机号">{{
        orderData.mobile
      }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
  orderData: {
    type: Object,
    default: () => ({})
  }
})

const show = computed(() => JSON.stringify(props.orderData) !== '{}')
</script>

<style lang="scss" scoped>
.order-descriptions {
  margin-top: 24px;
}
</style>
