<template>
  <div class="order-descriptions" v-if="show">
    <h3>订单对应商品</h3>
    <el-descriptions :column="2" border>
      <el-descriptions-item label="商品名称">{{
        goodsData.title
      }}</el-descriptions-item>
      <el-descriptions-item label="价格">{{
        goodsData.price
      }}</el-descriptions-item>
      <el-descriptions-item label="商品类型">{{
        goodsData.group
      }}</el-descriptions-item>
      <el-descriptions-item label="商品ID">{{
        goodsData.productId
      }}</el-descriptions-item>

      <el-descriptions-item label="商品有效期"
        >{{ goodsData.expire }}小时</el-descriptions-item
      >
      <el-descriptions-item label="tokens数量">{{
        goodsData.tokens
      }}</el-descriptions-item>

      <el-descriptions-item label="商品描述" :span="2">{{
        goodsData.desc
      }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
  goodsData: {
    type: Object,
    default: () => ({})
  }
})

const show = computed(() => JSON.stringify(props.goodsData) !== '{}')
</script>

<style lang="scss" scoped>
.order-descriptions {
  margin-top: 24px;
}
</style>
