import request from '@/utils/request'

/**
 * 查询我的订单
 */
export const findOrderList = (params) => {
  return request({
    url: '/v1/back/findOrderList',
    params
  })
}

/**
 * 查询订单详情
 */
export const getOrderInfoByOrderId = (params) => {
  return request({
    url: '/v1/back/getOrderInfoByOrderId',
    params
  })
}
