<template>
  <div class="order-list">
    <el-card class="serach-area">
      <div>
        <el-input
          class="mb"
          v-if="showAdminOperation"
          v-model="mobile"
          placeholder="输入手机号进行查询"
          @keyup.enter="getListData"
          clearable
        >
          <template #append>
            <el-button @click="getListData" :icon="Search" />
          </template>
        </el-input>

        <el-input
          class="mb"
          v-model="orderId"
          placeholder="输入订单ID进行查询"
          @keyup.enter="getListData"
          clearable
        >
          <template #append>
            <el-button @click="getListData" :icon="Search" />
          </template>
        </el-input>

        <span v-if="tip">{{ tip }}</span>
      </div>
    </el-card>

    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column label="#" type="index" />
        <el-table-column label="订单类型">
          <template #default="{ row }">
            {{ row.commodity ? row.commodity : ' ' }}
          </template>
        </el-table-column>
        <el-table-column prop="price" label="订单金额"> </el-table-column>
        <el-table-column prop="orderId" label="订单id"> </el-table-column>

        <el-table-column label="创建时间">
          <template #default="{ row }">
            {{ $filters.dateFilter(row.createTime, 'YYYY-MM-DD HH:mm:ss') }}
          </template>
        </el-table-column>

        <el-table-column label="购买时间">
          <template #default="{ row }">
            <div v-if="row.useTime">
              {{ $filters.dateFilter(row.payTime, 'YYYY-MM-DD HH:mm:ss') }}
            </div>
            <div v-else>暂未使用</div>
          </template>
        </el-table-column>

        <el-table-column label="下单人">
          <template #default="{ row }">
            <div v-if="row.user">
              {{ row.user.mobile }}
            </div>
            <div v-else>系统生成</div>
          </template>
        </el-table-column>

        <el-table-column label="订单状态">
          <template #default="{ row }">
            {{ $filters.orderFilter(row.status) }}
          </template>
        </el-table-column>

        <!-- 操作 -->
        <el-table-column
          :label="$t('msg.excel.action')"
          fixed="right"
          width="90"
        >
          <template #default="{ row }">
            <el-button
              type="primary"
              size="mini"
              @click="onShowClick(row.orderId)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[2, 5, 10, 20]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script setup>
import { ref, onActivated, computed } from 'vue'
import { findOrderList } from '@/api/order'
import { watchSwitchLang } from '@/utils/i18n'
import { useStore } from 'vuex'
import { Search } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'

// 数据相关
const tableData = ref([])
const total = ref(0)
const page = ref(1)
const size = ref(10)
const mobile = ref('')
const orderId = ref('')

const store = useStore()
const router = useRouter()

const tip = computed(() => {
  if (orderId.value && !tableData.value?.length) {
    return '如果查询不到订单可以联系上级代理进行查询'
  }
  return ''
})

const showAdminOperation = computed(() =>
  // [6, 7, 8, 9] 表示为 角色id
  [6, 7].includes(store.getters.maxlevel.id)
)

// 管理员默认查询所有订单，其他成员只能查看自己的
const _mobile = computed(() => {
  if (showAdminOperation.value) {
    return mobile.value
  } else {
    return store.getters.userInfo.mobile
  }
})

// 获取数据的方法
const getListData = async () => {
  const result = await findOrderList({
    page: page.value,
    size: size.value,
    mobile: _mobile.value,
    orderId: orderId.value
  })
  tableData.value = result.list
  total.value = result.total
}
getListData()
// 监听语言切换
watchSwitchLang(getListData)

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = (currentSize) => {
  size.value = currentSize
  getListData()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = (currentPage) => {
  page.value = currentPage
  getListData()
}

// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

/**
 * 查看按钮点击事件
 */
const onShowClick = (orderId) => {
  router.push(`/order/detail/${orderId}`)
}
</script>

<style lang="scss" scoped>
.order-list {
  .serach-area {
    margin-bottom: 12px;
  }

  .header {
    margin-bottom: 22px;
    text-align: right;
  }
  ::v-deep .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }

  .mb {
    margin-bottom: 24px;
  }
}
</style>
