<template>
  <div class="order-descriptions" v-if="show">
    <h3>订单对应用户</h3>
    <el-descriptions :column="2" border>
      <el-descriptions-item label="用户名">{{
        userData.username
      }}</el-descriptions-item>
      <el-descriptions-item label="手机号">{{
        userData.mobile
      }}</el-descriptions-item>
      <el-descriptions-item v-if="$store.getters.isAdmin" label="余额">{{
        userData.remainingSum
      }}</el-descriptions-item>
      <el-descriptions-item v-if="$store.getters.isAdmin" label="累积消费">
        {{ userData.cumulativeSpending }}
      </el-descriptions-item>
      <el-descriptions-item v-if="$store.getters.isAdmin" label="剩余tokens">{{
        userData.restTokens
      }}</el-descriptions-item>
      <el-descriptions-item
        v-if="$store.getters.isAdmin"
        label="累积消耗tokens"
        >{{ userData.totalTokens }}</el-descriptions-item
      >
      <el-descriptions-item v-if="$store.getters.isAdmin" label="累积提问">{{
        userData.questionsNum
      }}</el-descriptions-item>
      <el-descriptions-item label="注册时间">{{
        $filters.dateFilter(userData.registerTime, 'YYYY-MM-DD HH:mm:ss')
      }}</el-descriptions-item>
      <el-descriptions-item label="GPT3有效期">{{
        $filters.dateFilter(userData.expireAt, 'YYYY-MM-DD HH:mm:ss')
      }}</el-descriptions-item>
      <el-descriptions-item label="GPT4有效期">{{
        $filters.dateFilter(userData.expireAt4, 'YYYY-MM-DD HH:mm:ss')
      }}</el-descriptions-item>
      <el-descriptions-item label="分享链接" :span="2">
        {{ `https://gpt-plus.huat.xyz/#/?channel=${userData.uuid}` }}
      </el-descriptions-item>
      <el-descriptions-item label="我的角色" :span="2">
        <div v-if="userData.role && userData.role.length">
          <el-tag
            v-for="(role, index) in userData.role"
            :key="index"
            class="ml-2"
            :type="getRoundColor()"
            >{{ role.title }}</el-tag
          >
        </div>
        <div v-else>
          <el-tag :key="index" class="ml-2" :type="getRoundColor()"
            >普通用户</el-tag
          >
        </div>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { getRoundColor } from '@/utils/utils'

const props = defineProps({
  userData: {
    type: Object,
    default: () => ({})
  }
})

const show = computed(() => JSON.stringify(props.userData) !== '{}')
</script>

<style lang="scss" scoped>
.order-descriptions {
  margin-top: 24px;
}
</style>
