<template>
  <div class="order-info-container">
    <el-card class="order-container">
      <template #header>
        <div class="header">
          <span>订单详情</span>
        </div>
      </template>

      <OrderInfoComp :orderData="orderData" />
      <GoodsInfoComp :goodsData="goodsInfo" />
      <UserInfoComp :userData="userInfo" />
    </el-card>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { watchSwitchLang } from '@/utils/i18n'
import { getOrderInfoByOrderId } from '@/api/order'
import { useRoute } from 'vue-router'
import GoodsInfoComp from './components/goodsInfo.vue'
import UserInfoComp from './components/userInfo.vue'
import OrderInfoComp from './components/orderInfo.vue'

const route = useRoute()
const orderData = ref({})

const goodsInfo = computed(() => orderData.value?.goods ?? {})
const userInfo = computed(() => orderData.value?.user ?? {})

const getUserDetail = async () => {
  const orderId = route.params.orderId
  orderData.value = await getOrderInfoByOrderId({
    orderId
  })
}
getUserDetail()
// 语言切换
watchSwitchLang(getUserDetail)
</script>

<style lang="scss" scoped>
.print-box {
  margin-bottom: 20px;
  text-align: right;
}

.ml-2 {
  margin-right: 12px;
}

.info {
  margin-top: 24px;
}

.user-profile {
  text-align: center;
  .user-name {
    font-weight: bold;
  }

  .box-center {
    padding-top: 10px;
  }

  .user-role {
    padding-top: 10px;
    font-weight: 400;
  }
}

.user-info-box {
  width: 1024px;
  margin: 0 auto;
  .title {
    text-align: center;
    margin-bottom: 18px;
  }
  .header {
    display: flex;
    ::v-deep .el-descriptions {
      flex-grow: 1;
    }
    .avatar {
      width: 187px;
      box-sizing: border-box;
      padding: 30px 20px;
      border: 1px solid #ebeef5;
      border-left: none;
    }
    .remark {
      margin-right: 12px;
    }
  }
  .body {
    ul {
      list-style: none;
      li {
        span {
          margin-right: 62px;
        }
      }
    }
  }
  .foot {
    margin-top: 42px;
    text-align: right;
  }
}
</style>
